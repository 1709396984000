import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

function Seo({ description, title, children }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						phoneNum
						address {
							streetAddress
							addressLocality
							addressRegion
							postalCode
							addressCountry
						}
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const defaultTitle = site.siteMetadata?.title;
	const phoneNum = site.siteMetadata?.phoneNum;

	return (
		<>
			<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
			<title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
			<meta name="description" content={metaDescription} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={metaDescription} />
			<meta property="og:type" content="website" />

			<script type="application/ld+json">
				{JSON.stringify({
					"@context": "http://schema.org",
					"@type": ["Plumber", "Localbusiness"],
					"@id": "",
					mainEntityOfPage:
						"https://maps.google.com/maps?cid=10183307570550232164",
					additionalType: "https://en.wikipedia.org/wiki/Drainage",
					address: {
						"@type": "PostalAddress",
						addressLocality: site.siteMetadata?.address?.addressLocality,
						addressRegion: site.siteMetadata?.address?.addressRegion,
						postalCode: site.siteMetadata?.address?.postalCode,
						streetAddress: site.siteMetadata?.address?.streetAddress,
						addressCountry: site.siteMetadata?.address?.addressCountry,
					},
					name: "The Drain Line Ltd",
					alternatename: defaultTitle,
					description:
						"Drainage Gloucestershire, are a 24 Hour Drainage Specialists in Gloucestershire in United Kingdom, Call Us Today! Rapid Response, Highest Quality Service, Call on 01452 452049.",
					email: "contact@drainage-gloucestershire.uk",
					priceRange: "£££-£££££",
					openingHours: "Mo-Fri 08:00-17:00",
					telephone: phoneNum,
					URL: "https://a1-roofing-surrey.co.uk/",
					sameAs: "",
					logo: "https://drainage-gloucestershire.uk/favicon-32x32.png",
					image:
						"https://drainage-gloucestershire.uk/favicons/icon-384x384.png",
					hasMap: "https://goo.gl/maps/sGNVnqxKRn3Gc4uRA",
					geo: {
						"@type": "GeoCoordinates",
						latitude: "51.8679963",
						longitude: "-2.2416749",
					},
				})}
			</script>
			{children}
		</>
	);
}

export default Seo;
