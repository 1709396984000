import * as React from "react";

// import "./layout.css"

const Layout = ({ children }) => {
	return (
		<>
			<div className="font-sans subpixel-antialiased">
				<main>{children}</main>
			</div>
		</>
	);
};

export default Layout;
